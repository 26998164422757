
import "../css/custom-navbar.css";
import "../css/footer-component.css";
import "../css/horizontal-cards-component.css";
import "../css/image-title-description-component.css";
import "../css/main-header.css";
import "../css/questions-and-answers-component.css";
import "../css/services-component.css";
import "../css/our-partners-component.css";


import card_image_1_2x from "../img/card_image_1_2x.png";
import card_image_2_2x from "../img/card_image_2_2x.png";
import card_image_3_2x from "../img/card_image_3_2x.png";
import card_image_4_2x from "../img/card_image_4_2x.png";
import custom_ai_solution_2x from "../img/custom_ai_solution_2x.png";
import integration_and_deployment_2x from "../img/integration_and_deployment_2x.png";
import main_header_decoration_1 from "../img/main_header_decoration_1.svg";
import main_header_decoration_2 from "../img/main_header_decoration_2.svg";
import minus_icon from "../img/minus_icon.svg";
import plus_icon from "../img/plus-icon.svg";
import sentellent_icon_svg from "../img/sentellent_icon.svg";
import sentellent_logo_with_letters from "../img/sentellent_logo_with_letters.svg";
import sentellent_pioneering from "../img/sentellent_pioneering.png";
import partner_1 from "../img/partner_1.svg";
import partner_2 from "../img/partner_2.svg";
import partner_3 from "../img/partner_3.svg";
import facebook_logo from "../img/facebook_logo.svg";
import instagram_logo from "../img/instagram_logo.svg";
import x_logo from "../img/x_logo.svg";
import linkedin_logo from "../img/linkedin_logo.svg";

class NavBar extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `
      <div class="wrapper">
          <div class="wrapper-header">
              <label for="checkbox_toggle" class="hamburger">&#9776;</label>
              <a href="/" class="logo-mobile-navbar">
                <div class="logo">
                  <img class="logo-img" src="${sentellent_icon_svg}" alt="">
                </div>
              </a>
              <a href="/" class="logo-web-navbar">
                <div class="logo">
                  <img class="logo-img" src="${sentellent_logo_with_letters}" alt="">
                </div>
              </a>
              <nav class="navbar">
                <div class="container-nav">
                  <ul class="nav-links">
                    <input type="checkbox" id="checkbox_toggle" />
                    <div class="menu">
                      <li class="home-options">
                          <a href="/" class="home-option-text">Home</a>
                      </li>
                      <li>
                          <a href="#contact-us-component" class="contact-us-link">Contact Us</a>
                      </li>
                    </div>
                  </ul>
                </div>
              </nav>
              <div class="right-wrapper">
                  <div style="margin-right: 20px;"></div>
              </div>
          </div>
      </div>
    `;
  }
}

class MainHeader extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `
      <div class="main-header-component">
        <div class="ai-section">
          <div class="background-images">
              <img src="${main_header_decoration_2}" class="top-left-image" alt="Top Left Decorative Image">
              <img src="${main_header_decoration_1}" class="bottom-right-image" alt="Bottom Right Decorative Image">
          </div>
          <div class="content">
              <h1 class="main-title">A Generative AI Product<br>and Service Company</h1>
              <p class="description">
                  We empower businesses of all sizes to harness the potential of this cutting-edge technology and achieve remarkable results.
              </p>
          </div>
        </div>
      </div>
    `;
  }
}

class ImageTitleDescriptionComponet extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `
      <div class="image-title-description-componet">
        <div class="responsive-section">
          <div class="content-container">
              <img src="${sentellent_pioneering}" alt="AI Technology Image" class="responsive-image">
              <div class="text-container">
                  <h1 class="title">Sentellent: Pioneering the Future with Generative AI</h1>
                  <p class="description">
                      Welcome to Sentellent, where we harness the power of Generative AI to transform businesses and drive innovation. As a leading Generative AI Products and Services Company, we're committed to helping organizations unlock new possibilities and achieve unprecedented growth.
                  </p>
              </div>
          </div>
        </div>
      </div>
    `;
  }
}

class QuestionsAndAnswersComponet extends HTMLElement {
  constructor() {
    super();
    this.qaData = [];
  }

  connectedCallback() {
    const qaData = this.getAttribute('data-qa');
    if (qaData) {
      try {
        this.qaData = JSON.parse(qaData);
      } catch (e) {
        console.error('Invalid JSON format for data-qa attribute', e);
      }
    }
    
    this.render();
  }

  render() {
    this.innerHTML = `
      <div class="questions-and-anwers-componet">
        <div class="faq-section">
          <h2 class="faq-title">Have questions? We're here to help</h2>
          <div class="accordion">
            ${this.qaData.map(qa => `
              <div class="accordion-item">
                  <div class="accordion-header">
                      <span class="question">${qa.question}</span>
                      <img src="${plus_icon}" alt="Expand Icon" class="icon plus-icon">
                      <img src="${minus_icon}" alt="Collapse Icon" class="icon minus-icon">
                  </div>
                  <div class="accordion-content">
                      <p class="answer">${qa.answer}</p>
                  </div>
              </div>
            `).join('')}
          </div>
        </div>
      </div>
    `;
  }
}

class HorizontalCardsComponent extends HTMLElement {
  constructor() {
    super();
    // Default data if no attributes are passed
    this.cardsData = [];
    this.mainTitle = '';
  }

  connectedCallback() {
    // Get the data attribute and parse the JSON string
    const data = this.getAttribute('data-cards');
    if (data) {
      try {
        this.cardsData = JSON.parse(data);
      } catch (error) {
        console.error('Invalid JSON format for data-cards attribute', error);
      }
    }
    this.mainTitle = this.getAttribute('main-title') ?? 'Our products';
    this.render();
  }

  render() {
    this.innerHTML = `
      <div class="horizontal-cards-component">
        <div class="partners-section">
          <h2 class="section-title">${this.mainTitle}</h2>
          <div class="partners-container">
            ${this.cardsData.map(card => `
              <div class="partner-card">
                <div class="card-content">
                  <img src="${getImage(card.image)}" alt="${card.title}" class="card-image">
                  <div class="card-text">
                    <h3 class="partner-title">${card.title}</h3>
                    <div class="card-hover-content">
                      <p class="partner-description">${card.description}</p>
                      <a href="${card.url}" class="visit-button" target="_blank">Visit Now</a>
                    </div>
                  </div>
                </div>
              </div>
            `).join('')}
          </div>
        </div>
      </div>
    `;
  }
}

class ServicesComponent extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.servicesData = [
      {
        title: 'AI Strategy Consulting',
        description: 'Our seasoned consultants will work closely with you to identify key areas where Generative AI can drive growth, improve efficiency, and enhance customer experiences.',
        image: sentellent_pioneering
      },
      {
        title: 'Custom AI Solution Development',
        description: 'Our skilled developers will create tailored AI solutions that address your specific business challenges and objectives. From natural language processing to image recognition, we\'ll deliver the perfect AI solution for your needs.',
        image: custom_ai_solution_2x
      },
      {
        title: 'Integration and Deployment',
        description: 'Our team will ensure seamless integration of our AI products into your existing systems and workflows, minimizing disruption and maximizing value.',
        image: integration_and_deployment_2x
      }
    ];

    this.render();
    this.initializeHoverEffects();
    this.setInitialActiveState();
  }

  render() {
    const servicesHtml = this.servicesData.map((service, index) => `
      <div class="ai-service-item ${index === 0 ? 'active' : ''}" data-image="${service.image}">
        <h2 class="ai-service-title">${service.title}</h2>
        <p class="ai-service-description">${service.description}</p>
      </div>
    `).join('');

    this.innerHTML = `
      <div class="services-component">
        <div class="ai-business-section">
          <div class="services-title-section">
            <h1 class="ai-main-title">Elevate Your Business with Generative AI</h1>
            <p class="ai-subtitle">
                At Sentellent, we don't just provide products – we offer comprehensive services to ensure successful implementation of Generative AI solutions:
            </p>
          </div>
          <div class="ai-container">
            <div class="ai-content">
              <div class="ai-services">
                ${servicesHtml}
              </div>
              <div class="services-bottom">
                <p class="ai-consultation">
                    Let's work together to unlock the full potential of Generative AI for your business. Contact us today to schedule a consultation.
                </p>
              </div>
            </div>
            <div class="ai-image-container">
              <img src="${this.servicesData[0].image}" alt="AI Technology" class="ai-service-image">
            </div>
          </div>
        </div>
      </div>
    `;
  }

  initializeHoverEffects() {
    const serviceItems = this.querySelectorAll('.ai-service-item');
    const serviceImage = this.querySelector('.ai-service-image');

    serviceItems.forEach(item => {
      item.addEventListener('mouseover', () => this.updateActiveState(item, serviceItems, serviceImage));
    });
  }

  updateActiveState(item, serviceItems, serviceImage) {
    serviceItems.forEach(i => i.classList.remove('active'));

    item.classList.add('active');

    const newImageSrc = item.getAttribute('data-image');
    serviceImage.src = newImageSrc;
  }

  setInitialActiveState() {
    const serviceItems = this.querySelectorAll('.ai-service-item');
    if (serviceItems.length > 0) {
      serviceItems[0].classList.add('active');
    }
  }
}

class FooterComponent extends HTMLElement {
  connectedCallback() {
    const currentYear = new Date().getFullYear();
    const socialSection = `
      <div class="footer-social">
        <a href="https://x.com/sentellent16208" target="_blank">
          <img src="${x_logo}" alt="X(Twitter)" class="social-icon" />
        </a>
        <a href="https://www.facebook.com/profile.php?id=61560458433421" target="_blank">
          <img src="${facebook_logo}" alt="Facebook" class="social-icon" />
        </a>
        <a href="https://www.linkedin.com/company/sentellent/" target="_blank">
          <img src="${linkedin_logo}" alt="LinkedIn" class="social-icon" />
        </a>
        <a href="https://www.instagram.com/sentellent/" target="_blank">
          <img src="${instagram_logo}" alt="Instagram" class="social-icon" />
        </a>
      </div>
    `;
    this.innerHTML = `
      <div class="footer-component">
        <div class="footer">
          <div class="footer-top">
            <div class="footer-logo-section">
              <img src="${sentellent_icon_svg}" alt="Sentellent Logo" class="footer-logo" />
              <h2 class="footer-company-name">SENTELLENT</h2>
            </div>

            <div class="footer-links">
              <a href="#contact-us-component">Contact Us</a>
            </div>
          </div>
          <div class="footer-bottom">
            <p>© ${currentYear} SENTELLENT. All Rights Reserved</p>
          </div>
        </div>
      </div>
    `;
  }
}

class OurPartnersComponent extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `
      <div class="our-partners-component">
        <div class="partners-section">
          <h2 class="partners-title">Our Partners</h2>
          <div class="partners-logos">
            <a href="https://digitalt3.com/" class="logo-mobile-navbar" target="_blank">
              <div class="partner-logo">
                <img src="${partner_1}" />
              </div>
            </a>
            <a href="https://www.kavia.ai/" class="logo-mobile-navbar" target="_blank">
              <div class="partner-logo">
                <img src="${partner_2}" />
              </div>
            </a>
            <a href="https://www.ioet.com/" class="logo-mobile-navbar" target="_blank">
              <div class="partner-logo">
                <img src="${partner_3}" />
              </div>
            </a>
          </div>
        </div>
      </div>
    `;
  }
}


customElements.define("main-navbar", NavBar);
customElements.define("main-header", MainHeader);
customElements.define("image-title-description-componet", ImageTitleDescriptionComponet);
customElements.define("questions-and-anwers-componet", QuestionsAndAnswersComponet);
customElements.define("horizontal-cards-component", HorizontalCardsComponent);
customElements.define("services-component", ServicesComponent);
customElements.define("footer-component", FooterComponent);
customElements.define("our-partners-component", OurPartnersComponent);

function getImage(imageName) {
  const imagesMap = {
    sentellent_icon_svg: sentellent_icon_svg,
    sentellent_logo_with_letters: sentellent_logo_with_letters,
    main_header_decoration_1: main_header_decoration_1,
    main_header_decoration_2: main_header_decoration_2,
    sentellent_pioneering: sentellent_pioneering,
    card_image_1_2x: card_image_1_2x,
    card_image_2_2x: card_image_2_2x,
    card_image_3_2x: card_image_3_2x,
    card_image_4_2x: card_image_4_2x,
  };
  return imagesMap[imageName] ?? aaa_1_1x;
}

const accordionItems = document.querySelectorAll('.accordion-item');
accordionItems.forEach(item => {
    item.addEventListener('click', () => {
        item.classList.toggle('active');
    });
});

document.querySelectorAll('.partners-container').forEach(container => {
  container.addEventListener('wheel', (e) => {
    if (e.deltaY !== 0) {
      if (container.scrollLeft + e.deltaY > 0 && container.scrollLeft + e.deltaY < container.scrollWidth - container.clientWidth) {
        container.scrollLeft += e.deltaY;
        e.preventDefault();
      }
    }
  });
});